import chunk from 'lodash/chunk';
import unionWith from 'lodash/unionWith';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { formatDateStringToTz, getExclusiveEndDateWithTz } from '../../util/dates';
import { getListings } from '../../util/api';
import config from '../../config';

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
};

const resultIds = (data) => data.data.map((l) => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = (searchParams) => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = (response) => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = (e) => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = (response) => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = (e) => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchListings = (searchParams) => async (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const priceSearchParams = (priceParam) => {
    const inSubunits = (value) =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };

  const availabilityParams = (datesParam, minDurationParam) => {
    const dateValues = datesParam ? datesParam.split(',') : [];
    const hasDateValues = datesParam && dateValues.length === 2;
    const startDate = hasDateValues ? dateValues[0] : null;
    const endDate = hasDateValues ? dateValues[1] : null;

    const minDurationMaybe =
      minDurationParam && Number.isInteger(minDurationParam) && hasDateValues
        ? { minDuration: minDurationParam }
        : {};

    // Find configs for 'dates-length' filter
    // (type: BookingDateRangeLengthFilter)
    const filterConfigs = config.custom.filters;
    const idOfBookingDateRangeLengthFilter = 'dates-length';
    const dateLengthFilterConfig = filterConfigs.find(
      (f) => f.id === idOfBookingDateRangeLengthFilter
    );
    // Extract time zone
    const timeZone = dateLengthFilterConfig.config.searchTimeZone;

    return hasDateValues
      ? {
          start: formatDateStringToTz(startDate, timeZone),
          end: getExclusiveEndDateWithTz(endDate, timeZone),

          // When we have `time-partial` value in the availability, the
          // API returns listings that don't necessarily have the full
          // start->end range available, but enough that the minDuration
          // (in minutes) can be fulfilled.
          //
          // See: https://www.sharetribe.com/api-reference/marketplace.html#availability-filtering
          availability: 'time-partial',

          ...minDurationMaybe,
        }
      : {};
  };

  const { page, perPage, price, dates, minDuration, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const availabilityMaybe = availabilityParams(dates, minDuration);

  let params = {
    ...rest,
    ...priceMaybe,
    ...availabilityMaybe,
    per_page: perPage,
    pub_showForSearch: true,
  };

  if (searchParams.pub_otherFilters && searchParams.pub_otherFilters.includes('rse')) {
    params.pub_rse = true;
  }

  if (searchParams.pub_otherFilters && searchParams.pub_otherFilters.includes('instantBooking')) {
    params.pub_instantBooking = true;
  }
  delete params.pub_otherFilters;

  if (params.pub_prices) {
    // Sharetribe API doesn't support filtering parent listings by child listings prices
    // so we need to first query child all listings, then query parent listings
    // then we paginate parent listings based on the page and perPage params on the client side
    // performance issue here.

    const childListings = await dispatch(
      queryChildListings('', {
        pub_prices: params.pub_prices,
      })
    );
    delete params.pub_prices;

    const parentListingsIds = childListings
      ?.map((item) => item?.attributes?.publicData?.parent)
      .filter((value, index, array) => array.indexOf(value) === index); // remove duplicates

    const chunkedParentListingsIds = chunk(parentListingsIds, 100); // Sharetribe API limit 100 ids per request

    try {
      const parentListingsResponses = await Promise.all(
        chunkedParentListingsIds.map((ids) =>
          sdk.listings.query({ ...params, page: 1, per_page: 100, ids: ids.join(',') })
        )
      );

      const parentListingsAllData = parentListingsResponses
        .map((response) => response.data.data)
        .flat();
      const pageStart = (page - 1) * perPage;
      const pageEnd = page * perPage;
      const parentListingsPageData = parentListingsAllData.slice(pageStart, pageEnd);

      const parentListingAllIncluded = parentListingsResponses
        .map((response) => response.data.included)
        .flat();

      const response = {
        ...parentListingsResponses[0],
        data: {
          ...parentListingsResponses[0].data,
          data: parentListingsPageData,
          included: parentListingAllIncluded,
          meta: {
            ...parentListingsResponses[0].data.meta,
            page,
            perPage,
            totalItems: parentListingsIds.length,
            totalPages: Math.round(parentListingsIds.length / perPage),
          },
        },
      };

      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response));
      return response;
    } catch (e) {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    }
  }

  return sdk.listings
    .query(params)
    .then(async (response) => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .catch((e) => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

const filterParentsListing = async (parentListingsId, dispatch, filter) => {
  try {
    const childListingListResponse = await Promise.all(
      parentListingsId?.map((id) => dispatch(queryChildListings(id?.uuid, filter)))
    );
    console.log('childListingListResponse = ', childListingListResponse);
    return childListingListResponse
      ?.filter((i) => i?.length > 0)
      ?.map((i) => i[0]?.attributes?.publicData?.parent);
  } catch (error) {
    return [];
  }
};

export const setActiveListing = (listingId) => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = (searchParams) => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then((response) => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch((e) => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};

export const queryChildListings = (listingId, queryParams) => async (dispatch, getState, sdk) => {
  const params = {
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
    ],
    pub_category: 'space',
    pub_parent: listingId,
    ...queryParams,
  };
  const requestParams = { ...params, page: 1, per_page: 100 };

  if (queryParams.pub_prices) {
    delete requestParams.pub_parent;
    delete requestParams.page;
    delete requestParams.per_page;
  }

  let response = await getListings(requestParams);

  const totalPages = response?.data?.meta?.totalPages;
  if (queryParams.pub_prices && totalPages > 1) {
    // Sharetribe API limit 100 ids per request
    // So we loop over all pages and get all listings
    const allListingsResponses = await Promise.all(
      Array.from({ length: totalPages - 1 }).map((_, index) =>
        getListings({ ...requestParams, page: index + 2 })
      )
    );
    const allListings = allListingsResponses.flatMap((response) => response?.data?.data);
    response.data.data = allListings;
  }

  dispatch(addMarketplaceEntities(response));

  return new Promise((resolve) => resolve(response?.data?.data));
};
