/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

import banquetIcon from './assets/icon/banquet.svg';
import boardroomIcon from './assets/icon/boardroom.svg';
import classroomIcon from './assets/icon/classroom.svg';
import receptionIcon from './assets/icon/reception.svg';
import theatreIcon from './assets/icon/theatre.svg';
import amphitheaterIcon from './assets/icon/amphitheater.svg';
import ushapeIcon from './assets/icon/ushape.svg';

import entranceIcon from './assets/icon/entrance.svg';
import mainCourseIcon from './assets/icon/mainCourse.svg';
import dessertIcon from './assets/icon/dessert.svg';
import softDrinksIcon from './assets/icon/softDrinks.svg';
import waterIcon from './assets/icon/water.svg';
import wineIcon from './assets/icon/wine.svg';
import coffeeIcon from './assets/icon/coffee.svg';
import otherIcon from './assets/icon/other.svg';

export const category = [
  // @TODO remake after all listings create are update
  { key: 'venue', label: 'Venue', disabled: false, checked: true },
  { key: 'social', label: 'Social Activities', disabled: false },
  // { key: 'shuttle', label: 'Shuttle', disabled: false },
  { key: 'virtual', label: 'Virtual Events', disabled: false },
  { key: 'catering', label: 'Catering', disabled: false },
  // { key: 'space', label: 'Spaces', parent: 'venue' },
  // { key: 'menu', label: 'Menus', parent: 'catering' },
];

export const cateringType = [
  { key: 'welcomeBreakfast', label: 'Welcome breakfast' },
  { key: 'lunch', label: 'Lunch' },
  { key: 'dinner', label: 'Dinner' },
  { key: 'dayPackage', label: 'Day package' },
  { key: 'halfDayPackage', label: 'Half day package' },
];

export const foodType = [
  { key: 'french', label: 'French' },
  { key: 'indian', label: 'Indian' },
  { key: 'thai', label: 'Thai' },
  { key: 'chinese', label: 'Chinese' },
  { key: 'greek', label: 'Greek' },
  { key: 'italian', label: 'Italian' },
  { key: 'mexican', label: 'Mexican' },
  { key: 'japanese', label: 'Japanese' },
  { key: 'asian', label: 'Asian' },
  { key: 'korean', label: 'Korean' },
  { key: 'lebanese', label: 'Lebanese' },
  { key: 'mediterranean', label: 'Mediterranean' },
  { key: 'seafood', label: 'Seafood' },
  { key: 'scandinavian', label: 'Scandinavian' },
  { key: 'basque', label: 'Basque' },
  { key: 'pizzeria', label: 'Pizzeria' },
  { key: 'vietnamese', label: 'Vietnamese' },
  { key: 'fusion', label: 'Fusion' },
  { key: 'spanish', label: 'Spanish' },
  { key: 'bouchonLyonnais', label: 'Bouchon Lyonnais' },
];

export const menuType = [
  { key: 'seating', label: 'Seating' },
  { key: 'cocktail', label: 'Cocktail' },
  { key: 'breakfast', label: 'Breakfast' },
];

export const mealType = [
  { key: 'buffet', label: 'Buffet' },
  { key: 'cocktail', label: 'Cocktail' },
  { key: 'seatedMeal', label: 'Seated Meal' },
  { key: 'mealBoxes', label: 'Meal Boxes' },
  { key: 'toHeat', label: 'To Heat' },
];

export const closeTo = [
  { key: 'nature', label: 'Nature' },
  { key: 'sea', label: 'Sea' },
  { key: 'mountain', label: 'Mountain' },
  { key: 'beach', label: 'Beach' },
  { key: 'cityCenter', label: 'City Center' },
  { key: 'airport', label: 'Airport' },
  { key: 'metro', label: 'Metro' },
  { key: 'station', label: 'Station' },
];

export const specificDiets = [
  { key: 'vegan', label: 'Vegan' },
  { key: 'organicFood', label: 'Organic Food' },
  { key: 'halal', label: 'Halal' },
  { key: 'kosher', label: 'Kosher' },
  { key: 'glutenFree', label: 'Gluten Free' },
  { key: 'lactoseFree', label: 'Lactose Free' },
];

export const socialResponsibility = [
  { key: 'climateAction', label: 'Climate Action' },
  { key: 'shoppingInShortCircuits', label: 'Shopping in Short Circuits' },
  { key: 'goodHealthAndWellBeing', label: 'Good Health and Well-being' },
  { key: 'cleanWaterAndSanitation', label: 'Clean Water and Sanitation' },
  { key: 'sustainableCitiesAndCommunities', label: 'Sustainable Cities and Communities' },
  { key: 'genderEquality', label: 'Gender Equality' },
  { key: 'normeIso2021', label: 'Norme Iso 2021' },
  { key: 'qualityEducation', label: 'Quality Education' },
  { key: 'decentWorkAndEconomicGrowth', label: 'Decent Work and Economic Growth' },
  { key: 'responsibleConsumptionAndProduction', label: 'Responsible Consumption and Production' },
  { key: 'diversity', label: 'Diversity' },
  { key: 'noPoverty', label: 'No Poverty' },
  { key: 'reducedInequalities', label: 'Reduced Inequalities' },
  { key: 'partnershipsForTheGoals', label: 'Partnerships For The Goals' },
];

export const facilities = [
  { key: 'conditionedAir', label: 'Conditioned Air' },
  { key: 'parking', label: 'Parking' },
  { key: 'golf', label: 'Golf' },
  { key: 'bar', label: 'Bar' },
  { key: 'pool', label: 'Pool' },
  { key: 'airportShuttle', label: 'Airport Shuttle' },
  { key: 'meetingSpace', label: 'Meeting Space' },
  { key: 'disabledFacilities', label: 'Disabled Facilities' },
  { key: 'spa', label: 'Spa' },
];

export const otherFilters = [
  { key: 'rse', label: 'Label RSE' },
  { key: 'instantBooking', label: 'Instant booking' },
];

export const venueType = [
  { key: 'hotel', label: 'Hotel' },
  { key: 'youthHostel', label: 'Youth hostel' },
  { key: 'holidayVillage', label: 'Holiday Village' },
  { key: 'cottage', label: 'Cottage' },
  // { key: 'restaurant', label: 'Pub / Restaurant' },
  // { key: 'conference', label: 'Conference Center' },
  // { key: 'cultural', label: 'Cultural Venue' },
  { key: 'castleEstate', label: 'Castle / Estate' },
  { key: 'sports', label: 'Sports Venue' },
  { key: 'unusual', label: 'Unusual Place' },
  // { key: 'business', label: 'Business Center' },
  { key: 'meetingRoom', label: 'Meeting room' },
  { key: 'coworking', label: 'Coworking Space' },
  // { key: 'tv', label: 'Studio TV' },
  // { key: 'startup', label: 'Startup Incubator' },
  { key: 'other', label: 'Other' },
];

export const hotelCategory = [
  { key: 'palace', label: 'Palace' },
  { key: 'five_stars', label: '5 *' },
  { key: 'four_stars', label: '4 *' },
  { key: 'three_stars', label: '3 *' },
  { key: 'boutique', label: 'Boutique Hotel' },
  { key: 'other', label: 'Other' },
];

export const venueCloseTo = [
  { key: 'nature', label: 'Nature' },
  { key: 'sea', label: 'Sea' },
  { key: 'mountain', label: 'Mountain' },
  { key: 'beach', label: 'Beach' },
  { key: 'city_center', label: 'City center' },
  { key: 'airport', label: 'Airport' },
  { key: 'metro', label: 'Metro' },
  { key: 'station', label: 'Station' },
];

export const venueFacilities = [
  { key: 'visioconference', label: 'XXXX' },
  { key: 'restaurant', label: 'Restaurant' },
  { key: 'golf', label: 'Golf' },
  { key: 'airport_shuttle', label: 'Airport Shuttle' },
  { key: 'health_club', label: 'Health Club' },
  { key: 'disabled', label: 'Disabled Facilities' },
  { key: 'pool', label: 'Pool' },
  { key: 'spa', label: 'Spa' },
  { key: 'lightingSystem', label: 'Lighting System' },
];

export const meetingStyles = [
  { key: 'board', label: 'Board Room', icon: boardroomIcon },
  { key: 'class', label: 'Class Room', icon: classroomIcon },
  { key: 'banquet', label: 'Banquet', icon: banquetIcon },
  { key: 'theatre', label: 'Theatre', icon: theatreIcon },
  { key: 'amphitheater', label: 'Amphithéâtre', icon: amphitheaterIcon },
  { key: 'ushape', label: 'U-shape', icon: ushapeIcon },
  { key: 'reception', label: 'Reception', icon: receptionIcon },
];

export const coworkingStyles = [{ key: 'coworking', label: 'Coworking' }];

export const spacePricesScheme = [
  { key: 'perHour', label: 'Per Hour', group: 'roomOnly' },
  { key: 'morning', label: 'Morning', group: 'roomOnly' },
  { key: 'afternoon', label: 'Afternoon', group: 'roomOnly' },
  { key: 'fullday', label: 'Full Day', group: 'roomOnly' },
  { key: 'evening', label: 'Evening', group: 'roomOnly' },
];

export const packages = [
  'fulldayPackage',
  'morningPackage',
  'afternoonPackage',
  'residentialPackage',
];

export const spacePackagePricesScheme = [
  {
    key: 'fulldayPackage',
    label: 'Full Day Package',
    packageDetails: ['Location de la salle', '2 pauses', '1 Déjeuner'],
    group: 'packages',
  },
  {
    key: 'morningPackage',
    label: 'Morning Package',
    packageDetails: ['Location de la salle', '1 pause', '1 Déjeuner'],
    group: 'packages',
  },
  {
    key: 'afternoonPackage',
    label: 'Afternoon Package',
    packageDetails: ['Location de la salle', '1 pause', '1 Déjeuner'],
    group: 'packages',
  },
  {
    key: 'residentialPackage',
    label: 'Residential Package',
    packageDetails: [
      'Location de la salle',
      '2 pauses',
      '2 Déjeuners',
      'Hébergement partagé (lits individuels)',
      '1 Dîner',
      '1 Petit déjeuner',
    ],
    group: 'packages',
  },
];

export const menuPricesScheme = [{ key: 'menu', label: 'Menu' }];

export const menuItems = [
  { key: 'entrance', label: 'Entrance', icon: entranceIcon },
  { key: 'mainCourse', label: 'Main Course', icon: mainCourseIcon },
  { key: 'dessert', label: 'Dessert', icon: dessertIcon },
  { key: 'softDrinks', label: 'Soft Drinks', icon: softDrinksIcon },
  { key: 'water', label: 'Water', icon: waterIcon },
  { key: 'wine', label: 'Wine', icon: wineIcon },
  { key: 'hotDrinks', label: 'Hot drinks', icon: coffeeIcon },
  { key: 'other', label: 'Other', icon: otherIcon },
];

export const shuttlePricesScheme = [
  { key: 'perHour', label: 'Per Hour' },
  { key: 'morning', label: 'Morning' },
  { key: 'afternoon', label: 'Afternoon' },
  { key: 'fullday', label: 'Full Day' },
  { key: 'dinner', label: 'Dinner' },
];

export const socialActivityTypes = [
  { key: 'teamBuilding', label: 'Team Building' },
  { key: 'entertainment', label: 'Entertainment' },
  { key: 'coaching', label: 'Coaching' },
];
export const socialActivityWhereType = [
  { key: 'indoor', label: 'Indoor' },
  { key: 'outdoor', label: 'Outdoor' },
  { key: 'virtual_type', label: 'Virtual' },
  { key: 'hybrid', label: 'Hybrid' },
];
export const socialActivityThemesType = [
  { key: 'inspiring', label: 'Inspiring' },
  { key: 'creative', label: 'Creative' },
  { key: 'murderParty', label: 'Murder Party' },
  { key: 'challenge', label: 'Challenge' },
  { key: 'cultural_social', label: 'Cultural' },
  { key: 'rallye', label: 'Rallye' },
  { key: 'teamSpirit', label: 'Team Spirit' },
  { key: 'food', label: 'Food' },
  { key: 'party', label: 'Party' },
  { key: 'sport', label: 'Sport' },
  { key: 'other', label: 'Other' },
];
export const socialActivityLanguageType = [
  { key: 'french', label: 'French' },
  { key: 'english', label: 'English' },
  { key: 'spanish', label: 'Spanish' },
];

export const shuttleVehicleTypes = [
  { key: 'bus', label: 'Bus' },
  { key: 'miniBus', label: 'Mini Bus' },
  { key: 'businessVan', label: 'Business Van' },
  { key: 'standardVan', label: 'Standard Van' },
  { key: 'businessVehicle', label: 'Business Vehicle' },
  { key: 'standardVehicle', label: 'Standard Vehicle' },
  { key: 'ecoVehicle', label: 'Eco Vehicle' },
];
export const shuttleEnergyTypes = [
  { key: 'electric', label: 'Electric' },
  { key: 'hybrid', label: 'Hybrid' },
  { key: 'thermic', label: 'Thermic' },
];
export const shuttleWaitingTimeMaxTypes = [
  { key: '15minutes', label: '15 minutes' },
  { key: '30minutes', label: '30 minutes' },
  { key: '60minutes', label: '60 minutes' },
];
export const shuttleActivityLanguageType = [
  { key: 'french', label: 'French' },
  { key: 'english', label: 'English' },
  { key: 'spanish', label: 'Spanish' },
];
export const virtualTargetsTypes = [
  { key: 'brandAwareness', label: 'Brand Awareness' },
  { key: 'thoughtLeadership', label: 'Thought Leadership' },
  { key: 'generateNewLeads', label: 'Generate New Leads' },
  { key: 'network', label: 'Network' },
  { key: 'showcaseYourProductsServices', label: 'Showcase Your Products Services' },
  { key: 'shareYourStory', label: 'Share Your Story' },
  { key: 'createStrongConnection', label: 'Create Strong Connection with your audience' },
  { key: 'driveEngagement', label: 'Drive Engagement and Interactivity' },
  { key: 'showcase', label: 'Showcase' },
  { key: 'dataCollection', label: 'Data Collection' },
];
export const virtualLanguageType = [
  { key: 'french', label: 'French' },
  { key: 'english', label: 'English' },
  { key: 'spanish', label: 'Spanish' },
];

export const virtualServices = [
  { key: 'branding', label: 'Branding' },
  { key: 'invitations', label: 'Invitations' },
  { key: 'liveStreaming', label: 'Live Streaming' },
  { key: 'translations', label: 'Translations' },
  { key: 'eventManagement', label: 'Event Management' },
  { key: 'survey', label: 'Survey' },
  { key: 'registrationPage', label: 'Registration Page' },
  { key: 'captation', label: 'Captation' },
  { key: 'moderation', label: 'Moderation' },
  { key: 'liveTraduction', label: 'Live Traduction' },
  { key: 'reporting', label: 'Reporting' },
];

export const venueVirtualServices = [
  { key: 'videoConferenceDevice', label: 'Kit Videoconference (Screen / Camera, Micro)' },
  { key: 'slowInternet', label: 'Less than 30 Mbps (ADSL)' },
  { key: 'normalInternet', label: 'Between 30 and 50 Mbps (Fiber)' },
];

export const extrasTypes = [
  { key: 'foodAndDrinks', label: 'Food & Drinks' },
  { key: 'equipment', label: 'Equipment' },
  { key: 'staffActivities', label: 'Staff Activities' },
  { key: 'virtualServices', label: 'Virtual Services' },
];

export const filters = [
  {
    id: 'formulas',
    label: 'Packages',
    type: 'SelectMultipleFilterVenue',
    show: true,
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['pub_prices'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      options: [...spacePackagePricesScheme, ...spacePricesScheme],
    },
  },
  {
    id: 'cateringType',
    label: 'Type of catering',
    type: 'SelectMultipleFilterCatering',
    show: true,
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['pub_cateringType'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      options: cateringType,
    },
  },
  {
    id: 'typeOfSocialActivity',
    label: 'Type of Activities',
    type: 'SelectMultipleFilterSocialActivity',
    show: true,
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['pub_typeOfActivity'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      options: socialActivityTypes,
    },
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilterDefault',
    show: true,
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'minPrice',
    label: 'Price',
    type: 'PriceFilter',
    show: true,
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['pub_minPrice'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'capacity',
    label: 'Capacity',
    type: 'SelectMultipleManyFiltersDefault',
    show: true,
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['pub_maxAttendees', 'pub_safeSpacing', 'pub_antiCovid'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      options: meetingStyles,
    },
  },
  {
    id: 'roomStyle',
    label: 'Capacity',
    type: 'SelectMultipleManyFiltersVenue',
    show: true,
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: [
      'pub_roomStyle',
      'pub_maxAttendees',
      'pub_numberRooms',
      'pub_singleBedroomsCount',
      'pub_twinBedroomsCount',
      'pub_singleBedsCount',
    ],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      options: meetingStyles,
    },
  },
  {
    id: 'dates-length',
    label: 'Dates',
    type: 'BookingDateRangeFilter',
    show: true,
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates', 'minDuration'],
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/UTC',

      // Options for the minimum duration of the booking
      options: [
        { key: '0', label: 'Any length' },
        { key: '60', label: '1 hour', shortLabel: '1h' },
        { key: '120', label: '2 hours', shortLabel: '2h' },
      ],
    },
  },
  {
    id: 'category',
    label: 'Category',
    type: 'SelectMultipleFilter',
    show: false,
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['pub_category'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      options: category,
    },
  },
  {
    id: 'foodType',
    label: 'Food type',
    type: 'SelectMultipleFilterFoodType',
    show: true,
    group: 'secondary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['pub_foodType'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      options: foodType,
    },
  },
  {
    id: 'mealType',
    label: 'Meal Type',
    type: 'SelectMultipleFilterMealType',
    show: true,
    group: 'secondary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['pub_mealType'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      options: mealType,
    },
  },
  {
    id: 'specificDiets',
    label: 'Specific Diets',
    type: 'SelectMultipleFilterSpecificDiets',
    show: true,
    group: 'secondary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['pub_specificDiets'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      options: specificDiets,
    },
  },
  {
    id: 'socialResponsability',
    label: 'Social Responsability',
    type: 'SelectMultipleFilterSocialResponsability',
    show: true,
    group: 'secondary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['pub_socialResponsability'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      options: socialResponsibility,
    },
  },
  {
    id: 'typeOfVehicle',
    label: 'Type of Vehicle',
    type: 'SelectMultipleFilterShuttle',
    show: true,
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['pub_typeOfVehicle'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      options: shuttleVehicleTypes,
    },
  },
  {
    id: 'typeOfVirtualEvent',
    label: 'Type of Virtual Event',
    type: 'SelectMultipleFilterVirtual',
    show: true,
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['pub_eventType'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      options: [
        { key: 'virtual_type', label: 'Virtual' },
        { key: 'hybrid', label: 'Hybrid' },
      ],
    },
  },

  /*
   *
   *  Venue Filters
   *
   */
  {
    id: 'typeOfLocation',
    label: 'Type of Location',
    type: 'SelectMultipleFilterSecondaryVenue',
    show: true,
    group: 'secondary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['pub_locationType'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      options: [
        { key: 'coworking', label: 'Coworking Space' },
        { key: 'youthHostel', label: 'Youth hostel' },
        { key: 'holidayVillage', label: 'Holiday Village' },
        { key: 'hotel', label: 'Hotel' },
        { key: 'cottage', label: 'Cottage' },
        // { key: 'business', label: 'Business Center' },
        { key: 'meetingRoom', label: 'Meeting Room' },
        // { key: 'cultural', label: 'Cultural Venue' },
        { key: 'castleEstate', label: 'Castle / Estate' },
        { key: 'sports', label: 'Sports Venue' },
        { key: 'unusual', label: 'Unusual Place' },
        { key: 'other', label: 'Other' },
      ],
    },
  },
  {
    id: 'virtualServices',
    label: 'Wi-Fi :',
    type: 'SelectMultipleFilterSecondaryVenue',
    group: 'secondary',
    queryParamNames: ['pub_virtualServices'],
    show: true,
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: venueVirtualServices,
    },
  },
  {
    id: 'facilities',
    label: 'Facilities :',
    type: 'SelectMultipleFilterSecondaryVenue',
    group: 'secondary',
    queryParamNames: ['pub_facilities'],
    show: true,
    config: {
      searchMode: 'has_all',
      options: venueFacilities,
    },
  },
  {
    id: 'hotelCategory',
    label: 'Hotel category :',
    type: 'SelectMultipleFilterSecondaryVenue',
    group: 'secondary',
    queryParamNames: ['pub_hotelCategory'],
    show: true,
    config: {
      searchMode: 'has_any',
      options: hotelCategory,
    },
  },
  {
    id: 'closeTo',
    label: 'Close to :',
    type: 'SelectMultipleFilterSecondaryVenue',
    group: 'secondary',
    queryParamNames: ['pub_closeTo'],
    show: true,
    config: {
      searchMode: 'has_all',
      options: venueCloseTo,
    },
  },
  /*
   *
   *  Shuttle Filters
   *
   */
  {
    id: 'energy',
    label: 'Energy :',
    type: 'SelectMultipleFilterSecondaryShuttle',
    group: 'secondary',
    queryParamNames: ['pub_energy'],
    show: true,
    config: {
      searchMode: 'has_any',
      options: shuttleEnergyTypes,
    },
  },
  {
    id: 'waitingTimeMax',
    label: 'Waiting time max :',
    type: 'SelectMultipleFilterSecondaryShuttle',
    group: 'secondary',
    queryParamNames: ['pub_waitingTimeMax'],
    show: true,
    config: {
      searchMode: 'has_any',
      options: shuttleWaitingTimeMaxTypes,
    },
  },
  /*
   *
   *  Virtual Filters
   *
   */
  {
    id: 'target',
    label: 'Target :',
    type: 'SelectMultipleFilterSecondaryVirtual',
    group: 'secondary',
    queryParamNames: ['pub_targets'],
    show: true,
    config: {
      searchMode: 'has_all',
      options: virtualTargetsTypes,
    },
  },
  {
    id: 'services',
    label: 'Services :',
    type: 'SelectMultipleFilterSecondaryVirtual',
    group: 'secondary',
    queryParamNames: ['pub_services'],
    show: true,
    config: {
      searchMode: 'has_all',
      options: virtualServices,
    },
  },
  {
    id: 'language',
    label: 'Language :',
    type: 'SelectMultipleFilterSecondaryVirtual',
    group: 'secondary',
    queryParamNames: ['pub_virtualLanguage'],
    show: true,
    config: {
      searchMode: 'has_any',
      options: virtualLanguageType,
    },
  },
  /*
   *
   *  Social Filters
   *
   */
  {
    id: 'themes',
    label: 'Themes :',
    type: 'SelectMultipleFilterSecondarySocial',
    group: 'secondary',
    queryParamNames: ['pub_socialThemes'],
    show: true,
    config: {
      searchMode: 'has_all',
      options: socialActivityThemesType,
    },
  },
  {
    id: 'duration',
    label: 'Duration :',
    type: 'SelectMultipleFilterSecondarySocial',
    group: 'secondary',
    queryParamNames: ['pub_durationValue'],
    show: true,
    config: {
      options: [
        { key: '1', label: '1 hour' },
        { key: '2', label: '2 hours' },
        { key: '3', label: '3 hours' },
        { key: '4', label: '4 hours' },
        { key: '24', label: '1 day' },
      ],
    },
  },
  {
    id: 'where',
    label: 'Where :',
    type: 'SelectMultipleFilterSecondarySocial',
    group: 'secondary',
    queryParamNames: ['pub_socialWhereType'],
    show: true,
    config: {
      searchMode: 'has_all',
      options: socialActivityWhereType,
    },
  },
  {
    id: 'pubAmenities',
    label: 'Amenities',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_pubAmenities'],
    show: false,
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'naturalLight', label: 'Natural Light' },
        { key: 'soundSystem', label: 'Sound System' },
        { key: 'wifi', label: 'Wi-Fi' },
        { key: 'recording', label: 'Recording' },
        { key: 'photoReport', label: 'Photo report' },
        { key: 'hydroalcoholicGel', label: 'Hydroalcoholic gel' },
        { key: 'handSanitizer', label: 'Hand Sanitizer' },
        { key: 'certificate', label: 'Certificate' },
        { key: 'paperBoard', label: 'Paper Board' },
        { key: 'television', label: 'Television' },
        { key: 'paper', label: 'Paper' },
        { key: 'conditionedAir', label: 'Conditioned Air' },
        { key: 'projector', label: 'Projector' },
        { key: 'pens', label: 'Pens' },
        { key: 'screen', label: 'Screen' },
        { key: 'water', label: 'Water' },
        { key: 'slowInternet', label: 'Less than 30 Mbps (ADSL)' },
        { key: 'normalInternet', label: 'Between 30 and 50 Mbps (Fiber)' },
        { key: 'highSpeedInternet', label: 'More than 50 Mbps (Fiber)' },
        { key: 'tvStudio', label: 'TV Studio' },
        { key: 'greenScreen', label: 'Green Screen' },
        { key: 'videoConferenceDevice', label: 'Videoconference Device' },
        { key: 'avTechnicianOnSite', label: 'AV Technician on site' },
        { key: 'softDrinks', label: 'Soft Drinks' },
        { key: 'newspaper', label: 'Newspaper' },
        { key: 'mask', label: 'Mask' },
        { key: 'branding', label: 'Branding' },
        { key: 'invitations', label: 'Invitations' },
        { key: 'liveStreaming', label: 'Live Streaming' },
        { key: 'translations', label: 'Translations' },
        { key: 'eventManagement', label: 'Event Management' },
        { key: 'survey', label: 'Survey' },
        { key: 'registrationPage', label: 'Registration Page' },
        { key: 'captation', label: 'Captation' },
        { key: 'moderation', label: 'Moderation' },
        { key: 'liveTraduction', label: 'Live Traduction' },
        { key: 'reporting', label: 'Reporting' },
      ],
    },
  },
  {
    id: 'others',
    label: 'Others',
    type: 'SelectMultipleFilter',
    show: true,
    group: 'secondary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['pub_others'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      options: otherFilters,
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-pub_minPrice, -price', label: 'Lowest price' },
    { key: 'pub_minPrice, price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    // { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};
